export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  {
    text: "Πελάτες",
    icon: "group",
    items: [
      {
        text: "Διαχείριση Πελατών",
        path: '/ow-clients',
        icon: "isnotblank"
      }
    ]
  },
  {
    text: "Ραντεβού",
    icon: "clock",
    items: [
      /*
      {
        text: "Διαχείριση Ραντεβού",
        path: "/ow-appointments",
        icon: "isnotblank"
      },
      */
      {
        text: "Ημερήσιο Πρόγραμμα",
        path: '/ow-view-rep-daily-programme',
        icon: "isnotblank"
      },
      {
        text: "Οργάνωση μήνα",
        path: "/ow-view-rep-monthly-programme",
        icon: "isnotblank"
      }, 
      {
        text: 'Έλεγχος Ραντεβού',
        path: '/ow-view-appointments-check',
        icon: 'isnotblank'
      },
    ]
  },
  {
    text: "Προβολή",
    icon: "eyeopen",
    
    items: [
      /*
      {
        text: "Ραντεβού πελατών",
        path: '/ow-view-rep-clients-appointments',
        icon: "isnotblank"
      },
      */
      {
        text: "Πιστώσεις",
        path: "/ow-view-rep-credits",
        icon: "isnotblank"
      },
      {
        text: "Παραστατικά",
        path: "/ow-view-rep-credits-invoices",
        icon: "isnotblank"
      },
      {
        text: "Μηνύματα Τεχνικών",
        path: "/ow-appointments-messages",
        icon: "isnotblank"
      },
    ]
    
  },
  {
    text: "Εκτυπώσεις",
    icon: "print",
    items: [
        {
          text: 'Συγκεντρωτική Αναφορά Εισπράξεων Πελατών ανά Έτος και Μήνα',
          path: '/ow-view-rep-client-monthly-summary',
          icon: 'isnotblank'
        },
        {
          text: 'Συγκεντρωτική Αναφορά Εισπράξεων ανά Έτος και Μήνα',
          path: '/ow-view-rep-sales-monthly-yearly-summary',
          icon: 'isnotblank'
        }, 
        {
          text: 'Συγκεντρωτική Αναφορά Εργασιών ανά Έτος',
          path: '/ow-view-rep-sales-yearly-summary',
          icon: 'isnotblank'
        }, 
        {
          text: 'Αναλυτική Αναφορά Νέων Πελατών ανά Έτος και Πηγή',
          path: '/ow-view-rep-sales-peridical-forecast',
          icon: 'isnotblank'
        }, 
        {
          text: 'Αναλυτική Αναφορά Ραντεβού ανά Έτος και Πελάτη',
          path: '/ow-view-rep-clients-appointments',
          icon: 'isnotblank'
        }, 
        {
          text: 'Αναλυτική Αναφορά Εισπράξεων ανά Συχνότητα ανά Έτος και Πηγή',
          path: '/ow-view-rep-totalappfrequencyyearturnover',
          icon: 'isnotblank'
        },
        {
          text: 'Περιοδική Αναλυτική Αναφορά Εργασιών ανά Πηγή και Τύπο Πελάτη',
          path: '/ow-view-rep-client-appointments-analysis',
          icon: 'isnotblank'
        }, 
        {
          text: 'Αναλυτική Αναφορά Έξόδων Ανά Τεχνικό',
          path: '/ow-expenses',
          icon: 'isnotblank'
        },
      
    ]
    /*
    items: [
      {
        text: "Αναφορά Εργασιών Ανά Πηγή Πελάτη",
        path: "/αναφορά-εργασιών-ανά-πηγή-πελάτη",
        icon: "isnotblank"
      },
      {
        text: "Αναφορά Εργασιών Ανά Είδος Εργασίας",
        path: "/αναφορά-εργασιών-ανά-είδος-εργασίας",
        icon: "isnotblank"
      },
      {
        text: "Αναφορά Εργασιών Ανά Τεχνικό",
        path: "/αναφορά-εργασιών-ανά-τεχνικό",
        icon: "isnotblank"
      },
      {
        text: "Ετήσια Αναφορά Ανά Μήνα",
        path: "/ετήσια-αναφορά-ανά-μήνα",
        icon: "isnotblank"
      },
      {
        text: "Αναφορά Πωλήσεων - Νέοι Πελάτες",
        path: "/αναφορά-πωλήσεων",
        icon: "isnotblank"
      }
    ]
    */
  },
  {
    text: "Ρυθμίσεις",
    icon: "preferences",
    items: [
      {
        text: "Είδη Εργασιών",
        path: "/ow-work-types",
        icon: "isnotblank"
      }, 
      {
        text: 'Τύποι Πελατών',
        path: '/ow-client-types',
        icon: "isnotblank"
      }, 
      {
        text: 'Προτεραιότητες Ραντεβού',
        path: '/ow-appointment-status',
        icon: 'isnotblank'
      },
      {
        text: "Χρήστες",
        path: "/ow-users",
        icon: "isnotblank"
      }, 
      {
        text: 'Πηγές Πελατών',
        path: '/ow-clients-sources',
        icon: 'isnotblank'
      }, 
      {
        text: 'Κατηγορίες Πελατών',
        path: '/ow-clients-categories',
        icon: 'isnotblank'
      }, 
      {
        text: 'Τύποι Εξόδων',
        path: '/ow-expense-types',
        icon: 'isnotblank'
      }, 
      {
        text: 'Αιτιολογίες Ακύρωσης Ραντεβού',
        path: '/ow-appointment-cancellation-types',
        icon: 'isnotblank'
      },
    ]
  },
  ];