
import { loadMessages, locale } from 'devextreme/localization';
import config from "devextreme/core/config";

import '@/assets/fonts/Roboto-Regular-normal.js'

//import { jsPDF } from 'jspdf';
import 'jspdf-autotable'

// nf 20231008 Libraries for export
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
//

config({
    defaultCurrency: 'EUR'
});

const customMessages = {
    el: {
        'Cancel': "Ακύρωση",
        'Clear': "Καθαρισμός",
        'Done': "Τελείωσε",
        'Loading': "Φόρτωση...",
        'Select': "Επιλογή...",
        'Search': "Αναζήτηση",
        'Back': "Πίσω",
        'OK': "Εντάξει",
        'Yes': 'Ναι',
        'No': 'Όχι',
        'dxDataGrid-editingConfirmDeleteMessage': 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτή την εγγραφή; Θα διαγραφούν και όλα τα σχετικά δεδομένα!',
        'dxDataGrid-trueText': 'Ναι',
        'dxDataGrid-falseText': 'Όχι',
        "dxLookup-searchPlaceholder": "Αναζήτηση",
        "dxLookup-clearTitle": "Καθαρισμός",
        "dxLookup-title": "Επιλογή",
        "dxLookup-pullingDownText": "Τραβήξτε προς τα κάτω για να ανανεώσετε...",
        "dxLookup-pulledDownText": "Αφήστε για να ανανεώσετε...",
        "dxLookup-refreshingText": "Ανανέωση...",
        "dxLookup-pageLoadingText": "Φόρτωση...",
        "dxLookup-nextButtonText": "Περισσότερα",
        "dxLookup-searchingText": "Αναζήτηση...",
        'dxPager-all': 'Όλα',
    },
    // ... Other languages can be added as needed
};

// Then in your Vue's `onMounted` or another appropriate lifecycle hook:
loadMessages(customMessages)
locale('el')  // replace 'en' with your preferred locale if different

let host = location.host
let path = location.pathname
if (host.includes('localhost')) {
    host = "app.myeuroservice.com"
}

const Host =  {
    URI : 'https://' + host + path + 'data/webappapi-auth.php/records/',
    APIRoot : 'https://' + host + path + 'data/webappapi-auth.php/',
}

const appointmentsFrequencies = [
    { id: 12, name: '12 φορές το χρόνο', value: 12, }, 
    { id: 11, name: '11 φορές το χρόνο', value: 11, }, 
    { id: 10, name: '10 φορές το χρόνο', value: 10, }, 
    { id: 9, name: '9 φορές το χρόνο', value: 9, }, 
    { id: 8, name: '8 φορές το χρόνο', value: 8, }, 
    { id: 7, name: '7 φορές το χρόνο', value: 7, }, 
    { id: 6, name: '6 φορές το χρόνο', value: 6, }, 
    { id: 5, name: '5 φορές το χρόνο', value: 5, }, 
    { id: 4, name: '4 φορές το χρόνο', value: 4, }, 
    { id: 3, name: '3 φορές το χρόνο', value: 3, }, 
    { id: 2, name: '2 φορές το χρόνο', value: 2, }, 
    { id: 1, name: '1 φορά το χρόνο', value: 1, }
]

const months = [
    { id: 12, name: 'Δεκέμβριος', value: 12, }, 
    { id: 11, name: 'Νοέμβριος', value: 11, }, 
    { id: 10, name: 'Οκτώβριος', value: 10, }, 
    { id: 9, name: 'Σεπτέμβριος', value: 9, }, 
    { id: 8, name: 'Αύγουστος', value: 8, }, 
    { id: 7, name: 'Ιούλιος', value: 7, }, 
    { id: 6, name: 'Ιούνιος', value: 6, }, 
    { id: 5, name: 'Μάιος', value: 5, }, 
    { id: 4, name: 'Απρίλιος', value: 4, }, 
    { id: 3, name: 'Μάρτιος', value: 3, }, 
    { id: 2, name: 'Φεβρουάριος', value: 2, }, 
    { id: 1, name: 'Ιανουάριος', value: 1, }
]
const formatCurrency = {
    type: 'currency',
    currency: 'EUR', 
    useGrouping: true, 
    precision: 2 
}

/*

console.log("Host")
console.log(Host)
console.log("Path")
console.log(path)

*/

const loggedUser = {}
const logoLoginImageSource = 'images/logo-login-screen.png'
const logoMainImageSource = 'https://app.myeuroservice.com/images/logo-new.png'

const URL_SERVICE = Host.URI + 'sims_hw_last_code_VIEW';

export default {
    Host,
    loggedUser,
    logoLoginImageSource,
    logoMainImageSource,
    customMessages,
    appointmentsFrequencies,
    months,
    formatCurrency,

    methods: { 
        formatCurrencyNumber(value) {
            return new Intl.NumberFormat('el-GR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(value);
        },

        formatPercentNumber(value) {
            return new Intl.NumberFormat('el-GR', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(value);
        },
        async getLastInsertedCode() {
            return fetch(URL_SERVICE, {
                method: 'GET', headers: {'Accept': 'application/json',}, credentials: 'include',
              })
            .then(response => response.json())
            .then(json => json.records)
        },
        exportDataGridToPdfExcel(e, reportTitle, columnWidths = [40]){
            //console.log(e)
            if (e.format === 'xlsx') {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Main sheet');
                exportDataGrid({
                    component: e.component,
                    worksheet: worksheet,
                    customizeCell: function(options) {
                        options.excelCell.font = { name: 'Arial', size: 12 };
                        options.excelCell.alignment = { horizontal: 'left' };
                    } 
                }).then(function() {
                    workbook.xlsx.writeBuffer()
                        .then(function(buffer) {
                            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), reportTitle + '.xlsx');
                        });
                });
                e.cancel = true;
            } 
            else if (e.format === 'pdf') {
                const doc = new jsPDF()
                doc.setFont('Roboto-Regular')

                // Add a title to the document
                doc.setFontSize(16) // Set font size as per your requirement
                doc.text(reportTitle, 10, 17) // Adjust x, y coordinates as needed
                doc.setFontSize(8)

                exportDataGridToPdf({
                    jsPDFDocument: doc,
                    component: e.component,
                    indent: 15,
                    margin: { top: 20, right: 10, bottom: 10, left: 10 },
                    columnWidths: columnWidths,
                    customizeCell: function(options) {
                        const {gridCell, pdfCell} = options
                        pdfCell.font.size = 7;
                        pdfCell.font.style = 'normal';
                        if (gridCell.rowType === 'data' && gridCell.column.dataType === 'boolean') {
                            pdfCell.text = gridCell.value ? 'Ναι' : 'Όχι';
                        }
                    },
                }).then(() => {

                    const totalPages = doc.internal.getNumberOfPages();
                    const imageURL = logoMainImageSource //"path/to/your/image.png";  // URL or Base64 encoded string

                    const pageWidth = doc.internal.pageSize.getWidth();
                    const pageHeight = doc.internal.pageSize.getHeight();
                    const footerYPosition = pageHeight - 10;

                    const imageWidth = 10;  // The width you want the image to be displayed as
                    const imageHeight = 5;
                    
                    // Calculate the x-coordinate for right alignment
                    const imageXPosition = pageWidth - imageWidth - 10;  // Subtracting an additional 10 for a small margin from the edge
                    const imageYPosition = 10 - imageHeight;

                    const currentDate = new Date();

                    // Format the date in Greek
                    const formattedDate = new Intl.DateTimeFormat('el-GR', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}).format(currentDate);

                    for (let i = 1; i <= totalPages; i++) {
                        doc.setPage(i);

                        //Header
                        doc.addImage(imageURL, 'PNG', imageXPosition, imageYPosition, imageWidth, imageHeight, "logo"); // Adjust the y-coordinate and height as needed

                        doc.setFontSize(8);
                        //doc.text(reportTitle, 10, headerYPosition); // Adjust x, y coordinates as needed
                        doc.text(reportTitle, 10, imageYPosition + 3); 
                        doc.setFontSize(8);

                        doc.setDrawColor(200, 200, 200)
                        //doc.line(10, headerYPosition + lineOffset, pageWidth - 10, headerYPosition + lineOffset);
                        doc.line(10, 10, pageWidth - 10, 10)

                        // Add "Page x of y" at the bottom
                        const pageLabelText = `Σελίδα ${i} από ${totalPages}`;
                        const pageLabelWidth = doc.getStringUnitWidth(pageLabelText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                        const pageLabelX = pageWidth - pageLabelWidth - 10; // right alligned position

                        //const dateLabelWidth = doc.getStringUnitWidth(formattedDate) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                        const dateLabelX = 10 //pageWidth - dateLabelWidth - 10; // Centered position
                        
                        doc.line(10, footerYPosition, pageWidth - 10, footerYPosition)
                        doc.text(formattedDate, dateLabelX, footerYPosition + 4)
                        doc.text(pageLabelText, pageLabelX, footerYPosition + 4)

                        doc.setDrawColor(128, 128, 128)
                    }
                
                    doc.save(reportTitle + '.pdf')
                })
            }
        }

    }
}
