//import OwModuleModulusSms from './modules/ow-module-modulus-sms';
//import { asyncDispose } from 'core-js/fn/symbol';
import 'whatwg-fetch';

/*
const modulus = {
  APIKEY: 'f09fed18-47a1-4882-b26c-01b21d99fea1$_$hoTQOTVdS49ohQogHrKg',
  accessKey: 'f09fed18-47a1-4882-b26c-01b21d99fea1', 
  secretKey: 'hoTQOTVdS49ohQogHrKg',
  endPoint: 'https://messaging.modulus.gr/ott-api/message',
  authorization: 'Bearer',  
  senderName: 'MyEuroservice'
}
*/

const modulus = {
    APIKEY: 'ac346a4f-96a1-4b18-beb6-e688d3c615c2$_$4dK1vH3HT8ICvAHnQWIM',
    AccessKey: 'ac346a4f-96a1-4b18-beb6-e688d3c615c2', 
    secretKey: '4dK1vH3HT8ICvAHnQWIM',
    endPoint: 'https://messaging.modulus.gr/ott-api/message',
    authorization: 'Bearer',
    senderName: 'MyEuroservice'
}

export const sendSMS = async (clientName, appointmentDateFormated, appointmentTimeFormated, reminderDateTimeISO, phoneNo) => {
  try {
    // Send request
    // POST request using fetch with async/await;

    // trim all, 00, 30  but numbers from the phone number
    var phoneNoWithPrefix= phoneNo
    phoneNoWithPrefix= phoneNoWithPrefix.replace(/\D/g, "")
    phoneNoWithPrefix= phoneNoWithPrefix.replace(/^00/, "")
    phoneNoWithPrefix= phoneNoWithPrefix.replace(/^30/, "")
    // add leading +30 to the phone number
    phoneNoWithPrefix= "30" + phoneNoWithPrefix

    //const phoneNoWithPrefix= phoneNo;
    
    

    const messageBody = {
        "priority": 2,
        "recipients": [
            { 
                "msisdn": `${phoneNoWithPrefix}`
            }
        ],
      "sms": {
          "sender": "Euroservice",
          //"text": "ΥΠΕΝΘΥΜΙΣΗ ΡΑΝΤΕΒΟΥ \nΑΓΑΠΗΤΕ ΠΕΛΑΤΗ, ΣΑΣ ΕΝΗΜΕΡΩΝΟΥΜΕ ΟΤΙ ΤΟ ΡΑΝΤΕΒΟΥ ΑΠΕΝΤΟΜΩΣΗΣ ΓΙΑ ΤΟ ΧΩΡΟ ΣΑΣ ΕΙΝΑΙ ΤΗ " + appointmentDateTimeFormated + "\n ΕΥΧΑΡΙΣΤΟΥΜΕ \n WWW.EUROSERVICE.COM.GR",
          "text": "YΠENΘYMIΣH PANTEBOY: \nAΓAΠHTE MAΣ ΠEΛATH, ΣAΣ ENHMEPΩNOYME OTI H EΠIΣKEΨH MAΣ ΓIA THN AΠENTOMΩΣH TOY XΩPOY ΣAΣ ΘA ΓINEI, " + appointmentDateFormated + " KAI ΩPA " + appointmentTimeFormated +  ".\nEYXAPIΣTOYME!",
          "encoding": "gsm",
          "validityPeriod": 86400,
          "scheduleDateTime": reminderDateTimeISO
      },
      "externalTags": [
          "string"
      ]
    }
    
    const requestOptions = {
        method: 'POST',
        headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${modulus.APIKEY}`
        },
        body: JSON.stringify( messageBody ),
        credentials: 'omit',
    }

    await fetch(modulus.endPoint, requestOptions)
        .then(async response => {
            const data = await response.json()
            // check for error response
            if (!response.ok) {
                throw 'There was an error!' + (data && data.message) || response.status
            }
        })
        .catch(error => {
            throw 'There was an error!' + error
        })

    return {
        isOk: true,
        messageBody
    }

  }

  catch (e) {
      return {
          isOk: false,
      };
  }
 
}

export default {
  modulus,
  methods: { 

    }
}

/*
POST /ott-api/message HTTP/1.1
Authorization: Bearer f09fed18-47a1-4882-b26c-01b21d99fea1$_$hoTQOTVdS49ohQogHrKg
Host: messaging.modulus.gr
Content-Type: application/json
Content-Length: 252

{
  "priority": 2,
  "recipients": [
    {
      "msisdn": "306944295290"
    }
  ],
  "sms": {
    "sender": "NAUTILIA",
    "text": "Hello from NAUTILIA2",
    "encoding": "gsm",
    "validityPeriod": 86400
  },
  "externalTags": [
    "string"
  ]
}
*/
