<template>
  <div>
  <h3 class="content-block openware-form-title">{{ title }}</h3>

    <DxPopup
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"

        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        title="Διαχείριση Ραντεβού"
        :width="'90%'" 
        :height="'90%'"

        v-model:visible="isPopupVisible"
        titleTemplate="title"
        deferRendering="true"
        :animation="{
                      show: {
                          type: 'pop',
                          duration: 800,
                          from: {
                              scale: 0.90
                          }
                      }, 
                    }"
    >
        <DxScrollView height="100%" width="100%" ref="FormScrollViewRef">
            <AppointmentForm
                ref="appointmentForm"
                :appointment_id="selectedAppointmentID" 
                @closePopupEvent="onClosePopupEmit">
            </AppointmentForm>
        </DxScrollView>

    </DxPopup>

  <DxDataGrid
      ref="dataGrid"
      :key-expr="id"
      :data-source="CustomStoreMain"
      :columns="columns"
      :allow-column-reordering="false"
      :allow-column-resizing="false"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :focused-row-enabled="true"
      :word-wrap-enabled="true"
      :selection-filter="['appointments_frequency', '=', '3']"

      :scrolling="{ mode: 'standard', useNative: 'false' }"
      height="auto"

      @exporting="onExporting"
      @selectionChanged="onSelectionChanged"
      @init-new-row="onCustomInitNewRow"
      @initialized="onGridInitialized"
  >

      <DxPaging 
          :enabled="true"
          :page-size="0"
      />

      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="true" :allowHeaderFiltering="false"/>


      <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :display-mode="displayMode"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
      />

      <DxSorting mode="multiple"/>

      <DxEditing
          :allow-updating="true"
          :allow-adding="true"
          :allow-deleting="true"

          :texts= "{
                      addRow: 'Προσθήκη',
                      deleteRow: 'Διαγραφή',
                      editRow:'Επεξεργασία',
                      saveAllChanges:'Αποθήκευση Όλων',
                      cancelAllChanges:'Ακύρωση Όλων'
                  }"
          mode="row"
      >
      </DxEditing>

      <DxToolbar>
            <DxItem locate-in-menu="auto" location="before">
                <div class ="ow-list-filters" style="display: flex;">
                    <div class="ow-list-filter">
                        <label class="filter-label" for="selectBoxYear">Έτος:</label>
                        <DxSelectBox
                            ref="selectBoxYearRef"
                            id="selectBoxYear"
                            :data-source="CustomStoreLookup_ow_view_appointments_years"

                            value-expr="id"
                            display-expr="appointment_year"
                            placeholder="Έτος"
                            :show-clear-button="false"
                            :search-enabled="false"
                            :width="70"
                            @value-changed="onValueChangedYear"
                            @initialized="onInitializedYear"
                        />
                    </div>
                    <div class="ow-list-filter">
                        <label class="filter-label" for="selectBoxMonth">Μήνας:</label>
                        <DxSelectBox
                            ref="selectBoxMonthRef"
                            id="selectBoxMonth"
                            :data-source="CustomStoreLookup_ow_view_appointments_months_years"
                            value-expr="id"
                            display-expr="appointment_month"
                            placeholder="Μήνας"
                            :show-clear-button="false"
                            :search-enabled="false"
                            :width="50"
                            @value-changed="onValueChangedMonth"
                            @initialized="onInitializedMonth"
                        />
                    </div>
                    <div class="ow-list-filter">
                        <label class="filter-label" for="selectBoxDay">Ημέρα:</label>
                        <DxSelectBox
                            ref="selectBoxDayRef"
                            id="selectBoxDay"
                            :data-source="CustomStoreLookup_ow_view_appointments_days_months_years"
                            value-expr="appointment_day"
                            display-expr="appointment_day"
                            placeholder="Ημέρα"
                            :show-clear-button="false"
                            :search-enabled="false"
                            :width="50"
                            @value-changed="onValueChangedDay"
                            @initialized="onInitializedDay"
                        />
                    </div>
                    <div class="ow-list-filter">
                        <label class="filter-label" for="lookupConfirmed">Επιβεβαιωμένο:</label>
                        <DxLookupEx
                            ref="lookupConfirmedRef"
                            id="lookupConfirmed"
                            :searchEnabled="false"
                            :data-source="dataSourceConfirmed"
                            :value="1"
                            :width="90"
                            :input-attr="{ 'aria-label': 'Grouped lookup' }"
                            value-expr="value"
                            display-expr="title"
                            @initialized="onInitializedConfirmed"
                            @value-changed="onValueChangedConfirmed"
                        >
                        <DxDropDownOptions
                            :hide-on-outside-click="true"
                            :show-title="false"
                        />
                        </DxLookupEx>
                    </div>
                </div>
            </DxItem>

            <DxItem location="after">
                <DxDateBox 
                    placeholder="Ημερομηνία"
                    :show-clear-button="true"
                    :use-mask-behavior="true"
                />
            </DxItem>
            <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                onCustomInitNewRow()
                            }
                        }"

                />
            <DxItem name="exportButton" 
                :options="{
                        hint: 'Εξαγωγή δεδομένων'
                    }"
            />
      </DxToolbar>

      <!--
      <DxColumn
          data-field="app_date"
          caption="Ημερομηνία"
          :dataType="'date'"
      
          :format="{  type: 'datetime',
                      formatter: (value) => value.toLocaleString('el-GR', {
                          weekday: 'long', 
                          year: 'numeric', 
                          month: 'long', 
                          day: '2-digit',
                      })
                   }"
      >
      </DxColumn>
      -->

      <DxColumn
        data-field="work_completed_ind_bit"
        caption="Ολοκλήρωση"
        data-type="boolean"
        width="100"
        :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="app_hour"
          caption="Ώρα"
          sort-order="asc"
          width="70"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="client_name"
          caption="Πελάτης"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="client_address"
          caption="Διεύθυνση"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="responsible_persons"
          caption="Υπεύθυνος"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="admin_comment"
          caption="Μήνυμα"
          :hiding-priority="-1"          
      >
      </DxColumn>

      <DxColumn
          data-field="technician_id"
          caption="Τεχνικός"
          :hiding-priority="-1"
        >   
            <DxLookup
                :data-source="CustomStoreLookup_ow_users"
                value-expr="id"
                display-expr="name"
            />
      </DxColumn>

      <DxColumn
          data-field="technician_comment"
          caption="Σχόλιο τεχνικού"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="sum_total_price"
          caption="Κόστος Εργασίας"
          data-type="number"
          :format="formatCurrency"
          width="100"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="sum_collected_amount"
          caption="Ποσό Είσπραξης"
          data-type="number"
          :format="formatCurrency"
          width="100"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="vat_value"
          caption="Αξία ΦΠΑ"
          data-type="number"
          width="100"
          :format="formatCurrency"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
      </DxColumn>

      <DxSummary>
        <!--
            <DxGroupItem  
                column="sum_total_price"
                summary-type="sum"
                :value-format="formatCurrency"
                displayFormat="Κόστος: {0}"
            />

            <DxGroupItem  
                column="sum_collected_amount"
                summary-type="sum"
                :value-format="formatCurrency"
                displayFormat="Είσπραξη: {0}"
            />
        -->

            <DxGroupItem  
                column="vat_value"
                summary-type="sum"
                :value-format="formatCurrency"
                displayFormat="ΦΠΑ: {0}"
            />

          <DxTotalItem
              column="sum_total_price"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="Σ: {0}"
          />
          <DxTotalItem
              column="sum_collected_amount"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="Σ: {0}"
          />
          <DxTotalItem
              column="net_value"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="Σ: {0}"
          />
          <DxTotalItem
              column="vat_value"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="Σ: {0}"
          />
          <DxTotalItem
              column="total_value"
              summary-type="sum"
              :value-format="formatCurrency"
              displayFormat="Σ: {0}"
          />
      </DxSummary>

      <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
      />

  </DxDataGrid>

</div>

</template>

<script setup>
  import {
      DxDataGrid,
      DxColumn,
      DxEditing, 
      DxPaging, 
      DxPager,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxExport,
      DxLookup, 
      //DxRemoteOperations, //DxRequiredRule,
      DxToolbar,
      DxItem,
      DxButton,
      DxSummary,
      DxTotalItem,
      DxGroupItem
  } from 'devextreme-vue/data-grid'

  import 'whatwg-fetch'
  import CustomStore from 'devextreme/data/custom_store'

  import DxSelectBox from 'devextreme-vue/select-box'
  //import { DxCheckBox } from 'devextreme-vue/check-box'
  import { DxLookup as DxLookupEx, DxDropDownOptions } from 'devextreme-vue/lookup'

  import DxPopup from 'devextreme-vue/popup'
  import DxScrollView from 'devextreme-vue/scroll-view'
  import AppointmentForm from './ow-appointments-form.vue'

  import DataSource from 'devextreme/data/data_source'

  import OParameters from '../es-globals';

  import { ref, /*reactive, computed,*/ onMounted } from 'vue'

  //import { exportDataGrid } from 'devextreme/pdf_exporter'

  const dataGrid = ref(null)
  const FormScrollViewRef = ref(null)
  const appointmentForm = ref(null)

  const selectedAppointmentID = ref(null)
  const isPopupVisible = ref(false)
  const selectedYear = ref(new Date().getFullYear())
  const selectedMonth = ref(new Date().getMonth() + 1)
  const selectedDay = ref(new Date().getDate())
  const confirmedInd = ref(1)

  const lookupConfirmedRef = ref(null)
  const selectBoxYearRef = ref(null)
  const selectBoxMonthRef = ref(null)
  const selectBoxDayRef = ref(null)

  const title = 'Ημερήσιο Πρόγραμμα'
  const pageSizes = [5, 10, 20, 0]

  
  const formatCurrency = { 
                    type: 'currency',
                    currency: 'EUR', 
                    useGrouping: true, 
                    precision: 2 
                }


  const URL_SERVICE = OParameters.Host.URI + 'ow_appointments'
  const URL_SERVICE_ow_view_rep_daily_programme = OParameters.Host.URI + 'ow_view_rep_daily_programme'

  //const URL_SERVICE_ow_clients = OParameters.Host.URI + 'ow_clients'
  const URL_SERVICE_ow_users = OParameters.Host.URI + 'ow_users'

  //const URL_SERVICE_ow_work_types = OParameters.Host.URI + 'ow_work_types'

  const URL_SERVICE_ow_view_appointments_years = OParameters.Host.URI + 'ow_view_appointments_years'
  const URL_SERVICE_ow_view_appointments_months_years = OParameters.Host.URI + 'ow_view_appointments_months_years'
  const URL_SERVICE_ow_view_appointments_days_months_years = OParameters.Host.URI + 'ow_view_appointments_days_months_years'

  const confirmedIndFilter = [{ id: 1, title: 'Ναι', value: 1, }, { id: 2, title: 'Όχι', value: 0, }, { id: 3, title: 'Ναι & Όχι', value: -1, }]

  const dataSourceConfirmed = new DataSource({
        key: 'id',
        store: confirmedIndFilter,
  })

  const CustomStoreMain = new CustomStore({
        key: 'id',
        // loadMode: 'raw',
        load: () => {            
            const url = `${URL_SERVICE_ow_view_rep_daily_programme}?filter=app_date_formatted,eq,${selectedYear.value}-${selectedMonth.value.toString().padStart(2, '0')}-${selectedDay.value.toString().padStart(2, '0')}` 
                        + (confirmedInd.value > -1 ? `&filter=confirmed_ind,eq,${confirmedInd.value}` : '');
            return sendRequest(url)

        },
        //load: () => sendRequest(URL_SERVICE_ow_view_rep_daily_programme + '?filter=app_date_formatted,eq,' + selectedYear + '-' + selectedMonth.toString().padStart(2, '0') + '-' + selectedDay.toString().padStart(2, '0')),
        insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
        update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
        remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
        byKey: (key) => sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_users = new CustomStore({ 
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_users),
        byKey: (key) => sendRequest(URL_SERVICE_ow_users + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_view_appointments_years = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_view_appointments_years),
        //byKey: (key) => sendRequest(URL_SERVICE_ow_view_appointments_years + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_view_appointments_months_years = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_view_appointments_months_years),
        //byKey: (key) => sendRequest(URL_SERVICE_ow_view_appointments_months_years + "/" + encodeURIComponent(key)),
    })

    const CustomStoreLookup_ow_view_appointments_days_months_years = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: () => {
            console.log('CustomStoreLookup_ow_view_appointments_days_months_years')
            return sendRequest(URL_SERVICE_ow_view_appointments_days_months_years + '?order=appointment_day&filter=appointment_month,eq,' + selectedMonth.value + '&filter=appointment_year,eq,' + selectedYear.value)
        },
        //byKey: (key) => sendRequest(URL_SERVICE_ow_view_appointments_months_years + "/" + encodeURIComponent(key)),
    })

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
        // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        if (method === 'GET') {
            return fetch(url, {
                method,
                credentials: 'include',
            })
            .then(response => {
                return response.json().then(json => {
                    if (response.ok) return json.records;
                    throw json.Message;
                });
            });
        }
        return fetch(url, {
            method,
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            credentials: 'include',
        }).then((result) => {
            if (result.ok) {
                return result.text().then((text) => text && JSON.parse(text));
            }
            return result.json().then((json) => {
                throw json.Message;
            });
        });

    }

    const onExporting = (e) => {
        OParameters.methods.exportDataGridToPdfExcel(e, title)
    }

    const onGridInitialized = () => {
        onInitializedYear()
        onInitializedMonth()
        onInitializedDay()
    }

    const onInitializedConfirmed = () => {
        lookupConfirmedRef.value?.instance?.option('id', confirmedInd);
    }

    const onInitializedYear = () => {
        selectedYear.value = new Date().getFullYear()
        selectBoxYearRef.value?.instance?.option('value', selectedYear.value);
    }

    const onInitializedMonth = () => {
        selectedMonth.value = new Date().getMonth() + 1
        selectBoxMonthRef.value?.instance?.option('value', selectedMonth.value);
    }

    const onInitializedDay = () => {
        selectedDay.value = new Date().getDate()
        selectBoxDayRef.value?.instance?.option('value', selectedDay.value)
    }

    const onValueChangedYear = (e) => {
        selectedYear.value = e.value
        selectBoxDayRef.value?.instance?.option('value', '' )
        selectBoxMonthRef.value?.instance?.option('value', '' )
    }

    const onValueChangedConfirmed = (e) => {
        confirmedInd.value = e.value
        dataGrid.value?.instance?.refresh()
    }

    const onValueChangedMonth = (e) => {
        selectedMonth.value = e.value
        selectBoxDayRef.value?.instance?.option('value', '' )
        selectBoxDayRef.value?.instance?.getDataSource().reload()
    }

    const onValueChangedDay = (e) => {
        selectedDay.value = e.value
        dataGrid.value?.instance?.refresh()
    }

    const onSelectionChanged = (e) => {
        selectedAppointmentID.value = e.currentSelectedRowKeys[0]
    }

    const onClosePopupEmit = () => {
        FormScrollViewRef.value.instance.scrollTo(0)
        isPopupVisible.value = false
        dataGrid.value?.instance?.refresh()
    }

    const onCustomInitNewRow = () => {
        selectedAppointmentID.value = null;
        appointmentForm.value.loadFormData(selectedAppointmentID.value)
        isPopupVisible.value = true;
    }

    const onCustomRowEdit = (e) => {
        selectedAppointmentID.value = e.row.key
        appointmentForm.value.loadFormData(selectedAppointmentID.value)
        isPopupVisible.value = true
    }

    // Lifecycle hooks
    onMounted(() => {
    })

</script>
<style lang="scss">
    .dx-group-cell.dx-last-data-cell {
        font-weight: bold;
    }
</style>
