<template>
  <div>
  <h3 class="content-block openware-form-title">{{ title }}</h3>
  
  <DxPopup
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"

        :columns="columns"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        title="Διαχείριση Πελάτη"
        :width="'90%'" 
        :height="'90%'"

        v-model:visible="isPopupVisible"
        titleTemplate="title"
        deferRendering="true"
        :animation="{
                      show: {
                          type: 'pop',
                          duration: 800,
                          from: {
                              scale: 0.90
                          }
                      }, 
                    }"
    >
        <DxScrollView height="100%" width="100%" ref="FormScrollViewRef">
            <ClientsForm
                ref="clientsForm"
                :client_id="selectedClientID" 
                @closePopupEvent="onClosePopupEmit">
            </ClientsForm>
        </DxScrollView>

    </DxPopup>

  <DxDataGrid
      ref="dataGrid"
      :key-expr="id"
      :data-source="CustomStoreMain"
      :columns="columns"
      :allow-column-reordering="false"
      :allow-column-resizing="false"
      :column-hiding-enabled="false"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :focused-row-enabled="true"
      :word-wrap-enabled="true"
      @exporting="onExporting"
      @initNewRow="initNewRow"
      :scrolling="{ mode: 'standard' }" 
  >
      <DxPaging 
          :enabled="true"
          :page-size="5"
      />

      <DxFilterRow :visible="true"/>

      <DxHeaderFilter :visible="false"/>

      <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :display-mode="displayMode"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
      />

      <DxGroupPanel :visible="false"/>

      <DxSorting mode="multiple"/>

      <DxEditing
          :allow-updating="true"
          :allow-adding="true"
          :allow-deleting="true"
          :texts= "{
                     addRow: 'Προσθήκη',
                     deleteRow: 'Διαγραφή',
                     editRow:'Επεξεργασία',
                     saveAllChanges:'Αποθήκευση Όλων',
                     cancelAllChanges:'Ακύρωση Όλων'
                   }"
          mode="row"
      >
      </DxEditing>

      <DxToolbar>
        <DxItem locate-in-menu="auto" location="before">
                <div class ="ow-list-filters" style="display: flex;">
                    <div class="ow-list-filter">
                        <label class="filter-label" for="lookupActive">Ενεργός:</label>
                        <DxLookupEx
                            ref="lookupActiveRef"
                            id="lookupActive"
                            :searchEnabled="false"
                            :data-source="dataSourceActive"
                            :value="1"
                            :width="90"
                            :input-attr="{ 'aria-label': 'Grouped lookup' }"
                            value-expr="value"
                            display-expr="title"
                            @initialized="onInitializedActive"
                            @value-changed="onValueChangedActive"
                        >
                        <DxDropDownOptions
                            :hide-on-outside-click="true"
                            :show-title="false"
                        />
                        </DxLookupEx>
                    </div>
                    <div class="ow-list-filter">
                        <label class="filter-label" for="selectBoxYear">Πηγή Πελάτη:</label>
                        <DxSelectBox
                            ref="selectBoxClientSourcesRef"
                            id="selectBoxClientSources"
                            :data-source="CustomStoreLookup_ow_clients_sources"
                            value-expr="id"
                            display-expr="name"
                            :show-clear-button="true"
                            :search-enabled="false"
                            @initialized="onInitializedClientsSource"
                            @value-changed="onValueChangedClientsSource"
                        />
                    </div>
                </div>
            </DxItem>
            <DxItem location="after" 
                        widget="dxButton" 
                        :options="{
                            icon: 'add',
                            text: '',
                            hint: 'Προσθήκη',
                            onClick: () => {
                                onCustomInitNewRow()
                            }
                        }"

                />
            <DxItem name="exportButton" 
                :options="{
                        hint: 'Εξαγωγή δεδομένων'
                    }"
            />
      </DxToolbar>

      <!--
      <DxColumn
          data-field="active_ind"
          caption="Ενεργός"
          data-type="boolean"
          :hiding-priority="10"
          width="100"
      >
          <DxRequiredRule/>
      </DxColumn>
      -->

      <DxColumn
          data-field="name"
          caption="Επωνυμία"
          sort-order="asc"
          :hiding-priority="-1"
      >
          <DxRequiredRule/>
      </DxColumn>

      <!--
      <DxColumn
          data-field="clients_sources_id"
          caption="Πηγή Πελάτη"
          :hiding-priority="20"
      >
            <DxLookup
                :data-source="CustomStoreLookup_ow_clients_sources"
                value-expr="id"
                display-expr="name"
            />
      </DxColumn>
      -->

      <DxColumn
          data-field="address_street"
          caption="Οδός"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="address_city"
          caption="Πόλη"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="address_postal_code"
          caption="Τ.Κ."
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="responsible_person_name1"
          caption="Ονοματεπώνυμο Υπευθύνου"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="responsible_person_name2"
          caption="Ονοματεπώνυμο Υπευθύνου 2"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="phone_1"
          caption="Τηλέφωνο Υπευθύνου"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="phone_2"
          caption="Τηλέφωνο Υπευθύνου 2"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="mobile_phone_1"
          caption="Κινητό Υπευθύνου"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="subscription_amount"
          caption="Κόστος Εργασίας"
          data-type="number"
          :format="OParameters.formatCurrency"
          width="100"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="date_time"
          caption="Επιπλέον Πληροφορίες"
          :visible="false"
          :hiding-priority="-1"
      >
      </DxColumn>

      <DxColumn
          data-field="appointments_frequency"
          caption="Συχνότητα Εργασιών / Έτος"
          :hiding-priority="-1"
      >
            <DxLookup
                :data-source="OParameters.appointmentsFrequencies"
                value-expr="value"
                display-expr="name"
            />
      </DxColumn>

      <DxColumn type="buttons">
              <DxButton 
                  text="Επεξεργασία"
                  icon="edit"
                  hint="Επεξεργασία"
                  :on-click="onCustomRowEdit"
              />
              <DxButton name="delete" hint="Διαγραφή"/>
          >
      </DxColumn>

      <DxExport
          :enabled="true"
          :formats="['xlsx']"
          :texts= "{
                      exportAll: 'Εξαγωγή σε {0}',
                  }"
      />

  </DxDataGrid>
</div>
</template>

<script setup>
  import {
      DxDataGrid,
      DxColumn,
      DxEditing, 
      DxPaging, 
      DxPager,
      DxGroupPanel,
      DxSorting,
      DxFilterRow,
      DxHeaderFilter,
      DxExport,
      DxLookup, 
      DxRequiredRule,
      DxButton,
      DxToolbar,
      DxItem
  } from 'devextreme-vue/data-grid'
  import 'whatwg-fetch'
  

  import DxSelectBox from 'devextreme-vue/select-box'
  import DxPopup from 'devextreme-vue/popup'
  import DxScrollView from 'devextreme-vue/scroll-view'

  import { DxLookup as DxLookupEx, DxDropDownOptions } from 'devextreme-vue/lookup'

  import ClientsForm from './ow-clients-form.vue'

  import CustomStore from 'devextreme/data/custom_store'
  import DataSource from 'devextreme/data/data_source'

  import OParameters from '../es-globals'

  import { ref, /*reactive, computed, onMounted */} from 'vue'
  //import { exportDataGrid } from 'devextreme/pdf_exporter'

  const dataGrid = ref(null)
  const FormScrollViewRef = ref(null)
  
  const clientsForm = ref(null)

  const isPopupVisible = ref(false)
  const selectedClientID = ref(null)
  const selectedClientsSourceID = ref(null)

  const activeInd = ref(0)
  const lookupActiveRef = ref(null)
  const selectBoxClientSourcesRef = ref(null)
  
  const title = 'Διαχείριση Πελατών'
  const pageSizes = [5, 10, 20]

  const URL_SERVICE = OParameters.Host.URI + 'ow_clients'
  const URL_SERVICE_ow_clients_sources = OParameters.Host.URI + 'ow_clients_sources'

  const ActiveFilter = [{ id: 1, title: 'Ναι', value: 0, }, { id: 2, title: 'Όχι', value: 1, }, { id: 3, title: 'Ναι & Όχι', value: -1, }]

  const dataSourceActive = new DataSource({
        key: 'id',
        store: ActiveFilter,
  })
 
 /* const LookupCustomDS_ow_appointments_frequency = new DataSource({
            key: 'id',
            store: frequencyFilter,
  })
*/
  const CustomStoreMain = new CustomStore({
            key: 'id',
            //loadMode: 'raw',
            //load: () => sendRequest(URL_SERVICE),

            load: () => {            
                const url = `${URL_SERVICE}` + '?filter=id,ge,0' + (activeInd.value > -1 ? `&filter=active_ind,neq,${activeInd.value}` : '') +
                                                                   (selectedClientsSourceID.value != null ? `&filter=clients_sources_id,eq,${selectedClientsSourceID.value}` : '')
                //console.log(url)
                return sendRequest(url)
            },

            insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
            update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
            remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
            byKey: (key) => sendRequest(URL_SERVICE + "/" + encodeURIComponent(key)),
        })

  const CustomStoreLookup_ow_clients_sources = new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE_ow_clients_sources),
        byKey: (key) => sendRequest(URL_SERVICE_ow_clients_sources + "/" + encodeURIComponent(key)),
    })

    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
  const sendRequest = (url, method = 'GET', values = {}) => {
            // console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
            if (method === 'GET') {
                return fetch(url, {
                    method,
                    credentials: 'include',
                })
                .then((response) => response.json()
                .then((json) => {
                    if (response.ok) return json.records;
                    throw json.Message;
                }));
            }
            return fetch(url, {
                method,
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json', //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                credentials: 'include',
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                }
                return result.json().then((json) => {
                    throw json.Message;
                });
            });
        }

    const onExporting = (e) => {
        OParameters.methods.exportDataGridToPdfExcel(e, title)
    }

    const initNewRow = (e) => {
        e.data.date_time = new Date().toISOString().slice(0, 19).replace('T', ' ')
    }

    const onClosePopupEmit = () => {
        FormScrollViewRef.value.instance.scrollTo(0);
        isPopupVisible.value = false
        dataGrid.value?.instance?.refresh()
    }

    const onCustomInitNewRow = (/*e*/) => {
        // e.data.date_time = new Date().toISOString().slice(0, 19).replace('T', ' ');
        selectedClientID.value = null
        clientsForm.value.loadFormData(selectedClientID.value)
        isPopupVisible.value = true
    }

    const onCustomRowEdit = (e) => {
        selectedClientID.value = e.row.key
        clientsForm.value.loadFormData(selectedClientID.value)
        isPopupVisible.value = true
    }

    const onInitializedActive = () => {
        //console.log('onInitialized')
        //console.log(activeInd.value)
        
        lookupActiveRef.value?.instance?.option('value', activeInd.value);
    }

    const onValueChangedActive = (e) => {
        activeInd.value = e.value
        dataGrid.value?.instance?.refresh()
    }

    const onInitializedClientsSource = () => {
        selectBoxClientSourcesRef.value?.instance?.option('value', selectedClientsSourceID.value);
    }

    const onValueChangedClientsSource = (e) => {
        selectedClientsSourceID.value = e.value
        dataGrid.value?.instance?.refresh()
    }
</script>
<style lang="scss">
</style>
