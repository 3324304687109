<template>
  <div>    
    <div class="widget-container">

      <DxForm
            :form-data="formData"
            label-mode='outside'
            :read-only='false'
            :show-colon-after-label='true'
            label-location='top'
            :min-col-width='300'
            :col-count='2'
            onInitialized="onInitialized"
            ref="formMaster"
      >

        <DxColCountByScreen :xs="1" :sm="1" :md="1" :lg="1"/>

        <DxGroupItem caption="" :col-span="2" :col-count="2">
          <DxSimpleItem 
                data-field="block_user" 
                editor-type="dxCheckBox"
            >
                <DxLabel text="Αποκλεισμός Χρήστη" />
            </DxSimpleItem>
        </DxGroupItem>

        <DxGroupItem caption="" :col-span="2" :col-count="2">

          <DxSimpleItem 
                data-field="name"
                :is-required="true"
                :editor-options="{
                    disabled: !allowEditing,
                }"
            >
                <DxLabel text="Όνοματεπώνυμο"/>
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="email"
                :is-required="isInsertMode"
                :v-model="password"
                editor-type="dxTextBox"
                :editor-options="{
                    showClearButton: true,
                }"
            >
                <DxLabel text="Email"/>
                <DxEmailRule
                    message=""
                />
            </DxSimpleItem>

            <DxSimpleItem 
                data-field="username"
                :is-required="true"
                :editor-options="{
                    disabled: !allowEditing,
                }"
            >
                <DxLabel text="Όνομα Χρήστη"/>
            </DxSimpleItem>


            <DxSimpleItem 
                data-field="password"
                :is-required="isInsertMode"
                editor-type="dxTextBox"
                :editor-options="{
                    mode:'password',
                    showClearButton: true,
                }"
            >
                <DxLabel text="Κωδικός"/>
                <DxCustomRule
                    message="O Κωδικός πρέπει να έχει μήκος από 6 εώς 150 χαρακτήρες" 
                    :validation-callback="validateStringLength"
                />
            </DxSimpleItem>
        </DxGroupItem>

      </DxForm>

      <div id="buttons">
          <DxToolbar>
              <DxItem 
                  location="after"
                  widget="dxButton"
                  @click="onClickSave"
                  :options="{
                      icon: 'save',
                      text: 'Αποθηκευση'
                  }"
              >
              </DxItem>
              <DxItem 
                  location="after" 
                  widget="dxButton"
                  @click="onClickClose"
                  :options="{
                      icon: 'close',
                      text: 'Κλεισιμο'
                  }"
                  >
              </DxItem>
          </DxToolbar>
    </div>
    </div>
</div>
</template>

<script setup>

    const emit = defineEmits(['closePopupEvent'])

    import CustomStore from 'devextreme/data/custom_store';
    import {ref, watch, /*, reactive, computed, onMounted*/} from 'vue';

    import 'whatwg-fetch';
    import bcrypt from 'bcryptjs';

    import OParameters from '../es-globals';

    import {
        DxForm, 
        DxLabel,
        DxSimpleItem, 
        DxGroupItem,
        DxColCountByScreen,
    } from 'devextreme-vue/form'; 

    import {
          //DxRequiredRule,
          //DxAsyncRule,
          DxEmailRule,
          DxCustomRule
    } from 'devextreme-vue/validator';

    import {DxItem} from 'devextreme-vue/tab-panel'
    import DxToolbar from 'devextreme-vue/toolbar'

    var isInsertMode = false
    var isGridValidated = true
    //Form
    const URL_SERVICE = OParameters.Host.URI + 'ow_users'

    const formMaster = ref(null)
    const formData = ref({})
    const allowEditing = ref(true)

    watch(formData.value.id, (newValue, oldValue) => {
        console.log('Watch user_id-value-changed')
        emit('user_id-value-changed', newValue, oldValue)
        loadFormData(newValue)
    })

    /*
    const LookupCustomDS_sims_system_user_groups= new CustomStore({
          key: 'id',
          loadMode: 'raw',
          load: () => sendRequest(URL_SERVICE_LOOKUP_sims_system_user_groups + '?order=name'),
    })
    */

    const CustomStoreMain= new CustomStore({
        key: 'id',
        //loadMode: 'raw',
        load: () => sendRequest(URL_SERVICE),
        insert: (values) => sendRequest(URL_SERVICE, 'POST', values),
        update: (key, values) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'PUT', values),
        remove: (key) => sendRequest(URL_SERVICE + '/' + encodeURIComponent(key), 'DELETE'),
        byKey: (key) => sendRequestByKey(URL_SERVICE + "/" + encodeURIComponent(key)),
    })
    
    // 
    //  Based on: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CRUDOperations/Vue/GreenMist/
    //
    const sendRequest = (url, method = 'GET', values = {}) => {
          //console.log('sendRequest START LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
          if (method === 'GET') {
              return fetch(url, {
                  method,
                  credentials: 'include',
              })
              .then(response => response.json())
              .then(json => {
                  return json.records;
              })
          }
          return fetch(url, {
              method,
              body: JSON.stringify(values),
              headers: {'Content-Type': 'application/json',},
              credentials: 'include',
          }).then((result) => {
              if (result.ok) {
                  return result.text().then((text) => text && JSON.parse(text));
              }
              return result.json().then((json) => {
                  throw json.Message;
              });
          });
    }

    const sendRequestByKey = (url, method = 'GET') => {
        //console.log('LOG: ' + url + ' METHOD: ' + method + ' VALUES: ' + JSON.stringify(values, null, 4));
        return fetch(url, {
                  method,
                  headers: {
                      'Accept': 'application/json',
                  },
                  credentials: 'include',
              })
              .then(response => response.json())
    }
  
    const loadFormData = async (id) => {
        // nf 28/6/2023 
        // init disposal value in order to invoke onchange event which disables dispose field        

        formData.value = {}
        if (id != null) {
            isInsertMode = false
            formData.value = await CustomStoreMain.byKey(id)
            
        } else {
            isInsertMode = true
        }
        formData.value.date_time = new Date().toISOString().slice(0, 19).replace('T', ' ')
    }

    const validateStringLength = (params) => {
        if ((params.value.length > 0 && !isInsertMode)||(isInsertMode)) {
            return params.value.length >= 6 && params.value.length <= 150
        } else {
            return true
        }
    }

    const onClickSave = async () => {
        
        const formValidator = formMaster.value.instance.validate()
        isGridValidated = true
        let formSaved = false

        if (formValidator.isValid) {
            
            formData.value.password= await hashPassword(formData.value.password)

            console.log("formData.value.password", formData.value.password)

            if (!isInsertMode) {
                await saveData()
                formSaved = true
            } else {
                await insertData()
                formSaved = true
            }
        }

        if (isGridValidated && formSaved) emit('closePopupEvent', true)

    }

    const saveData = async () => {
        // console.log('Save data');
          return await CustomStoreMain.update(formData.value.id, formData.value);
    }

    const insertData = async () => {
        // console.log('Insert data');
         return await CustomStoreMain.insert(formData.value);
    }

    async function hashPassword(password) {
        const saltRounds = 10;
        const hashedPassword = await bcrypt.hash(password, saltRounds);
        console.log('Hashed password:', hashedPassword);
        return hashedPassword;
    }

    const onClickClose = () => {
          emit('closePopupEvent', true)
    }

    defineExpose({
        loadFormData
    });

</script>


<style lang="scss">

</style>

<style scoped>

#form {
   margin-top: 25px;
}
.caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
.dx-fieldset-header {
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 4px;
}
</style>
